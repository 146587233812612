import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Shop } from '@box-types';

@Injectable({ providedIn: 'root' })
export class GlobalStateService {
  public shopsSource = new BehaviorSubject<Shop[]>([]);
  public shops$ = this.shopsSource.asObservable();

  public setShops(shops: Shop[]): void {
    this.shopsSource.next(shops);
  }

  public getShops(): Shop[] {
    return this.shopsSource.getValue();
  }
}

<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <ng-container *ngIf="state === 'INIT'; else settingsState">
    <header #dialogHeader class="box-dialog-header">
      <box-dialog-header
        [canClose]="false"
        [title]="'cookies_and_similar_technologies' | translate"
      ></box-dialog-header>
    </header>
    <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
      <div class="cookies-consent-dialog-wrapper">
        <p *ngFor="let infoText of infoTexts">
          {{ infoText.text }}
        </p>
      </div>
    </main>
    <footer #dialogFooter class="box-dialog-footer cookies-consent-dialog-footer">
      <div class="cookies-consent-dialog-links-container">
        <a
          [attr.href]="['/account/personal-data/policy']"
          target="_blank"
          rel="noopener noreferrer"
          [attr.aria-label]="'privacy_policy_inform' | translate"
          class="cookies-consent-dialog-link"
          >{{ 'privacy_policy_inform' | translate }}</a
        >
        <a
          [routerLink]="['/account/personal-data/policy']"
          fragment="partner_list_link"
          target="_blank"
          rel="noopener noreferrer"
          [attr.aria-label]="'partner_list' | translate: { _PARTNERS_NUMBER: partnersNumber }"
          class="cookies-consent-dialog-link"
          >{{ 'partner_list' | translate: { _PARTNERS_NUMBER: partnersNumber } }}</a
        >
      </div>
      <div class="cookies-consent-dialog-buttons-container">
        <button
          box-button
          theme="secondary"
          borders="none"
          class="cookies-consent-dialog-settings-button"
          (click)="onSettingsClick()"
        >
          <mat-icon class="cookies-consent-dialog-settings-button-icon">settings</mat-icon>
          {{ 'settings_' | translate }}
        </button>
        <div class="cookies-consent-dialog-right-buttons">
          <button box-button theme="primary" (click)="onAcceptNecessary()">
            {{ 'only_required' | translate }}
          </button>
          <button box-button theme="primary" (click)="onAcceptAll()">
            {{ 'accept_all' | translate }}
          </button>
        </div>
      </div>
    </footer>
  </ng-container>
  <ng-template #settingsState>
    <header #dialogHeader class="box-dialog-header">
      <box-dialog-header
        [canClose]="false"
        [title]="'settings_for_cookies_and_similar_technologies' | translate"
      ></box-dialog-header>
    </header>
    <main #dialogBody bodyScroll class="box-dialog-content" (bodyScroll)="onBodyScroll($event)">
      <div class="cookies-consent-dialog-wrapper">
        <cookies-consent-settings
          *ngIf="state === 'SETTINGS'"
          [cookiesConsentData]="cookiesConsent"
          (cookiesConsentClose)="onSubmit()"
          (cookiesConsentBack)="onBack()"
        ></cookies-consent-settings>
      </div>
    </main>
  </ng-template>
</div>

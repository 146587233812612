import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {
  PaymentTypesService,
  AddCouponService,
  CartService,
  AnalyticsService,
  CouponTimerService,
  CouponsService
} from '@box-core/services';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { Payment, Coupon } from '@box-types';
import { CheckoutCouponsService, CheckoutStateService } from '@box-checkout/services';
import { finalize, skip, map } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CheckoutCouponService } from './checkout-coupon.service';

@Component({
  selector: 'checkout-coupon',
  templateUrl: './checkout-coupon.component.html',
  styleUrls: ['./checkout-coupon.component.scss'],
  providers: [CheckoutCouponService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutCouponComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public startingPrice: number;

  public loading: boolean;
  public imageSrc: string;
  public couponDescription: string;
  public coupons: Coupon[];
  public coupon: Coupon;
  public bestCoupon: Coupon;

  private cartSubscription: Subscription;
  private couponSubscription: Subscription;
  private couponsSubscription: Subscription;
  private paymentTypeSubscription: Subscription;
  private pointsDiscountSubscription: Subscription;
  private expirationSubscription: Subscription;

  constructor(
    private checkoutCouponService: CheckoutCouponService,
    private cartService: CartService,
    private checkoutCouponsService: CheckoutCouponsService,
    private addCouponService: AddCouponService,
    private paymentTypesService: PaymentTypesService,
    private changeDetectorRef: ChangeDetectorRef,
    private checkoutStateService: CheckoutStateService,
    private analyticsService: AnalyticsService,
    private couponTimerService: CouponTimerService,
    private couponsService: CouponsService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.checkoutCouponService
      .initialize()
      .pipe(
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe(() => {
        this.setCouponsSubscription();
        this.setCouponSubscription();
        this.setCartSubscription();
        this.setPaymentTypeSubscription();
        this.setPointsDiscountSubscription();
        this.setExpirationSubscription();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startingPrice) {
      this.startingPrice = changes.startingPrice.currentValue as number;
      this.couponDescription = this.checkoutCouponsService.getCouponDescription(this.startingPrice);
      this.bestCoupon = this.checkoutCouponsService.getBestDiscountCoupon(this.coupons, this.startingPrice); //getBestCoupon
    }
  }

  ngOnDestroy(): void {
    this.couponSubscription?.unsubscribe();
    this.couponsSubscription?.unsubscribe();
    this.paymentTypeSubscription?.unsubscribe();
    this.cartSubscription?.unsubscribe();
    this.checkoutCouponsService.clearCoupon();
    this.pointsDiscountSubscription?.unsubscribe();
    this.expirationSubscription?.unsubscribe();
  }

  public onToggleClick(event: Event): void {
    // when the user clicks on the toggle switch we don't want to trigger an onCouponClick event
    event.stopPropagation();
    this.triggerAnalyticsEvent();
  }

  public onCouponClick(): void {
    if (!this.checkoutCouponsService.isPaymentTypeValid()) {
      return void this.checkoutCouponsService.showPaymentErrorDialog();
    }
    if (!this.coupons?.length) {
      return this.addCouponService.initiateAddCouponFlow(this.startingPrice);
    }
    this.checkoutCouponsService.openCouponsDialog().subscribe((response) => {
      if (response?.addCoupon) {
        this.addCouponService.initiateAddCouponFlow(this.startingPrice);
      }
    });
  }

  public onSlideChange(event: MatSlideToggleChange): void {
    event.source.checked = false; // prevents default behaviour of enabling the toggle
    if (this.coupon) {
      return void this.checkoutCouponsService.clearCoupon();
    }
    if (!this.checkoutCouponsService.isPaymentTypeValid()) {
      return void this.checkoutCouponsService.showPaymentErrorDialog();
    }

    if (!this.coupons?.length) {
      return this.addCouponService.initiateAddCouponFlow(this.startingPrice);
    }
    if (this.coupons.length === 1) {
      const coupon = this.coupons[0];
      if (!coupon.disabledText) return this.checkoutCouponsService.selectCoupon(coupon);
    }
    this.checkoutCouponsService.openCouponsDialog().subscribe((response) => {
      if (response?.addCoupon) {
        this.addCouponService.initiateAddCouponFlow(this.startingPrice);
      }
    });
  }

  private updateCheckoutCoupons(): void {
    if (!this.checkoutCouponsService.isPaymentTypeValid()) return;
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.checkoutCouponsService
      .updateCheckoutCoupons()
      .pipe(
        map(() => this.checkoutCouponsService.findCouponAfterUpdate()),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((newCoupon: Coupon) => {
        if (!newCoupon || newCoupon.disabledText) return this.checkoutCouponsService.clearCoupon();
        this.checkoutCouponsService.setCoupon(newCoupon);
      });
  }

  private setCouponSubscription(): void {
    this.couponSubscription = this.checkoutCouponsService.coupon$.subscribe((coupon) => {
      this.coupon = coupon;
      this.setExpirationSubscription();
      this.couponDescription = this.checkoutCouponsService.getCouponDescription(this.startingPrice);
      this.bestCoupon = this.checkoutCouponsService.getBestDiscountCoupon(this.coupons, this.startingPrice); //getBestCoupon
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCouponsSubscription(): void {
    this.couponsSubscription = this.checkoutCouponsService.coupons$.subscribe((coupons) => {
      this.coupons = coupons;
      this.bestCoupon = this.checkoutCouponsService.getBestDiscountCoupon(this.coupons, this.startingPrice);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCartSubscription(): void {
    this.cartSubscription = this.cartService.cart$.pipe(skip(1)).subscribe(() => this.updateCheckoutCoupons());
  }

  private setPaymentTypeSubscription(): void {
    this.paymentTypeSubscription = this.paymentTypesService.payment$
      .pipe(
        skip(1),
        distinctUntilChanged((prev: Payment, curr: Payment) => prev.type === curr.type)
      )
      .subscribe(() => this.updateCheckoutCoupons());
  }

  private setPointsDiscountSubscription(): void {
    this.pointsDiscountSubscription = this.checkoutStateService.pointsDiscount$
      .pipe(
        distinctUntilChanged((prev: number, curr: number) => {
          const currValue = curr ?? 0;
          const prevValue = prev ?? 0;
          if (prevValue > 0 && currValue > 0) return true;
          if (prevValue === 0 && currValue === 0) return true;
          return false;
        })
      )
      .subscribe(() => {
        this.updateCheckoutCoupons();
      });
  }

  private setExpirationSubscription(): void {
    if (!this.coupon) return;
    this.expirationSubscription?.unsubscribe();
    this.expirationSubscription = this.couponTimerService.whenCouponIsExpired$(this.coupon.code).subscribe(() => {
      const filteredCoupons = this.coupons.filter((c) => c.code !== this.coupon.code);
      this.checkoutCouponsService.setCoupons(filteredCoupons);
      this.couponsService.showCouponExpirationNotification(this.coupon.code);
      this.checkoutCouponsService.clearCoupon();
      this.changeDetectorRef.detectChanges();
    });
  }

  private triggerAnalyticsEvent(): void {
    if (this.checkoutStateService.getPointsDiscount() > 0 && !this.coupon?.isCombinedWithPointsRedemption) {
      this.analyticsService.addGACustomEvent('ineligible_coupon_tapped_for_points', {});
    } else if (this.checkoutStateService.getPointsDiscount() > 0) {
      this.analyticsService.addGACustomEvent('coupon_taped_with_points_redemption', {});
    }
  }
}

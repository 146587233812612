import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Shop, DeliveryMethod } from '@box-types';
import { DeliveryMethodService, ShopService, DialogService, AnalyticsService, CartService } from '@box-core/services';
import { getShopEstimationTimeText, getAddShippingInfoEventGAConfig } from '@box/utils';
import { Subscription, combineLatest } from 'rxjs';
import { DaasAvailabilityService } from '@box-core/services/daas-availability.service';
import { CheckoutCouponsService } from '@box-checkout/services';
import { LanguageService } from '@box-core/services/language.service';

@Component({
  selector: 'delivery-method',
  templateUrl: './delivery-method.component.html',
  styleUrls: ['./delivery-method.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryMethodComponent implements OnInit, OnDestroy {
  public deliveryMethod: DeliveryMethod;
  public deliveryTitle: string;
  public takeawayTitle: string;

  public shop: Shop;
  private shopSubscription: Subscription;
  private deliveryMethodSubscription: Subscription;

  constructor(
    private shopService: ShopService,
    private deliveryMethodService: DeliveryMethodService,
    private daasAvailabilityService: DaasAvailabilityService,
    private dialogService: DialogService,
    private changeDetectorRef: ChangeDetectorRef,
    private cartService: CartService,
    private analyticsService: AnalyticsService,
    private checkoutCouponsService: CheckoutCouponsService,
    private languageService: LanguageService
  ) {}

  @HostBinding('class') public hostClass = 'delivery-method';

  ngOnInit(): void {
    this.setShopSubscription();
    this.setDeliveryMethodSubscription();
  }

  ngOnDestroy(): void {
    this.shopSubscription?.unsubscribe();
    this.deliveryMethodSubscription?.unsubscribe();
  }

  private setDeliveryMethodSubscription(): void {
    this.deliveryMethodSubscription = this.deliveryMethodService.deliveryMethod.subscribe((method) => {
      this.deliveryMethod = method;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setShopSubscription(): void {
    this.shopSubscription = combineLatest([
      this.shopService.shop,
      this.daasAvailabilityService.daasAvailability
    ]).subscribe(([shop, availability]) => {
      this.shop = shop;
      const deliveryEstimationTime = getShopEstimationTimeText(shop, 'delivery', availability);
      const deliveryTitleTranslated = this.languageService
        .getTextByKey('delivery_in_time')
        .replace('_TIME', deliveryEstimationTime);
      this.deliveryTitle = deliveryEstimationTime ? deliveryTitleTranslated : 'Delivery';
      const takeawayEstimationTime = getShopEstimationTimeText(shop, 'takeAway', availability);
      const takeawayTitleTranslated = this.languageService
        .getTextByKey('take_away_in_time')
        .replace('_TIME', takeawayEstimationTime);
      this.takeawayTitle = takeawayEstimationTime ? takeawayTitleTranslated : 'Take away';
      this.changeDetectorRef.detectChanges();
    });
  }

  public onDeliveryMethodClick(method: DeliveryMethod): void {
    if (method === 'takeAway' && !this.shop.takeAway) {
      return void this.dialogService.openInfoDialog({
        messages: ['the_takeaway_choice_from', 'the_shop_is_unavailable']
      });
    }
    this.deliveryMethodService.saveDeliveryMethod(method, this.shop);
    this.triggerAnalyticsEvent(method);
  }

  private triggerAnalyticsEvent(method: DeliveryMethod): void {
    const purchaseEvent = this.analyticsService.getPurchaseEvent(this.shop._id);
    if (!purchaseEvent) return;
    const options = {
      coupon: this.checkoutCouponsService.getCoupon(),
      cart: this.cartService.getCart(),
      shop: this.shop,
      purchaseEvent: purchaseEvent,
      categories: this.shopService.getShopItemsFromMemory().categories,
      deliveryMethod: method
    };
    const gaConfig = getAddShippingInfoEventGAConfig(options);
    this.analyticsService.addGAEcommerceEvent('add_shipping_info', gaConfig);
  }
}

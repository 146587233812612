<div *ngIf="showDisabledBackground" class="coupon-disabled-background">
  <span class="coupon-disabled-text ods-typography-microcopy-regular">{{ disabledText }}</span>
</div>

<div class="coupon-container ticket" [ngClass]="{ 'ticket--active coupon-active': active, 'coupon-unavailable-container': !available || showDisabledBackground }">
  <div class="coupon-stub">
    <div *ngIf="logo" class="coupon-logo" [style.background-image]="logo"></div>
    <span
      [style.color]="primaryTextColor"
      [style.text-shadow]="'1px 1px ' + primaryTextShadowColor"
      [style.font-size]="primaryTextSize"
      [class.coupon-primary-text-disabled]="showDisabledBackground"
      [class.coupon-primary-text-unavailable]="!available || couponDisabled"
      class="ods-typography-title-l coupon-primary-text"
      >{{ primaryText }}</span
    >
  </div>
  <div class="coupon-counterfoil">
    <div class="coupon-counterfoil-first-row">
      <span
        [style.color]="secondaryTextColor"
        class="ods-typography-microcopy-bold coupon-secondary-text"
        [class.coupon-secondary-text-unavailable]="!available || showDisabledBackground || couponDisabled"
        >{{ secondaryText | translate }}</span
      >
      <coupon-countdown
        *ngIf="showCountDown"
        class="coupon-countdown"
        [couponCode]="code"
        [expirationDate]="expirationDate"
      ></coupon-countdown>
      <div *ngIf="couponUnAvailable" class="coupon-unavailable-label">
        <span
          [class.coupon-counterfoil-text-disabled]="showDisabledBackground"
          class="ods-typography-microcopy-bold coupon-counterfoil-text"
          >{{ counterfoilText }}</span
        >
      </div>
    </div>

    <span
      class="coupon-description ods-typography-body-s-regular"
      [class.coupon-description-disabled]="showDisabledBackground"
      [class.truncate]="showCode"
      [innerHTML]="shortDescription"
    ></span>
    <span
      *ngIf="code && showCode"
      class="ods-typography-body-s-regular coupon-code"
      [class.coupon-code-disabled]="showDisabledBackground"
      >{{ 'code_' | translate }} {{ code }}</span
    >
  </div>
</div>

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostBinding
} from '@angular/core';
import {
  getCouponImagePath,
  getCouponPrimaryText,
  getCouponPrimaryTextColor,
  getCouponPrimaryTextShadowColor,
  getPrimaryTextSizeCheckout,
  getCouponBenefitText
} from '@box/utils';
import { Coupon } from '@box-types';
import { ThemingService } from '@box-core/services/theming.service';
import { currencyCode } from '@box-core/services/currency.service';
import { translate } from '@box-core/services';

@Component({
  selector: 'checkout-coupon-image',
  templateUrl: './checkout-coupon-image.component.html',
  styleUrls: ['./checkout-coupon-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckoutCouponImageComponent implements OnChanges {
  @Input() public coupon: Coupon;
  @Input() public active: boolean;
  @Input() public amount: number;
  public imageSrc: string;
  public amountDescription: string;
  public primaryTextColor: string;
  public primaryTextSize: string;
  public primaryTextShadowColor: string;
  public primaryText: string;

  constructor(private changeDetectorRef: ChangeDetectorRef, private themingService: ThemingService) {}

  @HostBinding('class') public hostClass = 'checkout-coupon-image';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coupon) {
      this.coupon = changes.coupon.currentValue as Coupon;
      this.amountDescription = getCouponBenefitText(this.coupon, this.amount, translate, currencyCode);
      this.primaryText = getCouponPrimaryText(this.coupon, currencyCode);
      this.primaryTextColor = getCouponPrimaryTextColor(this.coupon);
      this.primaryTextSize = getPrimaryTextSizeCheckout(this.amountDescription);
      this.primaryTextShadowColor = getCouponPrimaryTextShadowColor(this.coupon, this.themingService.getTheme());
      this.imageSrc = getCouponImagePath(this.coupon);
      this.changeDetectorRef.detectChanges();
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Shop, Coupon, GACouponConfig } from '@box-types';
import { BoxDialogWrapperComponent } from '@box-shared/components';
import {
  couponExpiresWithinDaysRange,
  getCouponPrimaryText,
  getCouponSecondaryText,
  isCouponDisabled
} from '@box/utils';
import { CouponDetailsDialogService } from './coupon-details-dialog.service';
import { CouponDetailsDialogData, CouponDetailsDialogResponse } from './coupon-details-dialog.types';
import { AnalyticsService, CouponTimerService, translate } from '@box-core/services';
import { Subscription } from 'rxjs';
import { currencyCode } from '@box-core/services/currency.service';

@Component({
  selector: 'coupon-details-dialog',
  templateUrl: './coupon-details-dialog.component.html',
  styleUrls: ['./coupon-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CouponDetailsDialogService]
})
export class CouponDetailsDialogComponent extends BoxDialogWrapperComponent implements OnInit, OnDestroy {
  private coupon: Coupon;
  private shops: Shop[];

  public detailsDescription: string;
  public expirationDateText: string;
  public redemptionDateText: string;
  public remainingValueText: string;
  public filteredShops: Shop[];
  public showCta: boolean;
  public buttonText: string;
  public code: string;
  public dialogTitle: string;
  public expirationDate: string;
  public closeToExpiration: boolean;
  private couponTimerSubscription: Subscription;
  public showCountDown: boolean;

  constructor(
    public override renderer: Renderer2,
    private dialogRef: MatDialogRef<CouponDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CouponDetailsDialogData,
    private couponDetailsDialogService: CouponDetailsDialogService,
    private couponTimerService: CouponTimerService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {
    super(renderer);
  }

  @HostBinding('class') public hostClass = 'coupon-details-dialog';

  ngOnInit(): void {
    this.coupon = this.data.coupon;
    this.shops = this.data.shops;
    this.detailsDescription = this.couponDetailsDialogService.getCouponDetailsDescription(this.coupon);
    this.expirationDateText = this.couponDetailsDialogService.getCouponDetailsExpirationDateText(this.coupon);
    this.redemptionDateText = this.couponDetailsDialogService.getCouponDetailsRedemptionDateText(this.coupon);
    this.remainingValueText = this.couponDetailsDialogService.getCouponDetailsRemainingValueText(this.coupon);
    this.filteredShops = this.couponDetailsDialogService.getCouponDetailsShops(this.coupon, this.shops);
    this.buttonText = this.couponDetailsDialogService.getCouponDetailsCtaTitle(this.coupon);
    this.showCta = this.shouldShowCTA();
    this.code = this.coupon.code;
    this.expirationDate = this.coupon.expirationDate;
    this.dialogTitle = this.getTitle(this.coupon);
    this.closeToExpiration = couponExpiresWithinDaysRange(this.coupon, 7);
    this.setCouponTimerSubscription();
  }

  ngOnDestroy(): void {
    this.couponTimerSubscription?.unsubscribe();
  }

  public onShopCardClick(shop: Shop): void {
    this.triggerAnalyticsShopClickEvent();
    this.closeDialog({ triggerAction: false, triggerShop: shop });
  }

  public onCouponAction(): void {
    this.closeDialog({ triggerAction: true });
  }

  public closeDialog(data?: CouponDetailsDialogResponse): void {
    this.dialogRef.close(data);
  }

  private shouldShowCTA(): boolean {
    if (this.data.showCta === false) return false;
    if (isCouponDisabled(this.coupon)) return false;
    if (!this.buttonText) return false;
    if (this.filteredShops?.length > 0) return false;
    return true;
  }

  private setCouponTimerSubscription(): void {
    this.couponTimerSubscription = this.couponTimerService.getCouponTimerState$(this.code).subscribe((state) => {
      this.showCountDown = state === 'COUPON_ACTIVE' && this.closeToExpiration;
      this.changeDetectorRef.detectChanges();
    });
  }

  private getTitle(coupon: Coupon): string {
    const primaryText = getCouponPrimaryText(coupon, currencyCode);
    const secondaryText = translate(getCouponSecondaryText(coupon));
    if (!primaryText || !secondaryText) return translate('coupon_info');
    return getCouponPrimaryText(coupon, currencyCode) + ' ' + translate(getCouponSecondaryText(coupon));
  }

  private triggerAnalyticsShopClickEvent(): void {
    const coupon = this.coupon;
    if (!this.data?.source) return;
    const gaConfig = { source: this.data.source, synergy: coupon.synergy } as GACouponConfig;
    this.analyticsService.addGACustomEvent('shop_view_from_coupon', gaConfig);
  }
}

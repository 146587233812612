import { Injectable } from '@angular/core';
import { CheckoutCouponsService } from '@box-checkout/services';
import { CouponsService, DialogService, PaymentTypesService } from '@box-core/services';
import { isCouponCheckoutPreselected } from '@box/utils';
import { Observable, tap } from 'rxjs';
import { Coupon } from '@box-types';

@Injectable()
export class CheckoutCouponService {
  constructor(
    private checkoutCouponsService: CheckoutCouponsService,
    private paymentTypesService: PaymentTypesService,
    private dialogService: DialogService,
    private couponsService: CouponsService
  ) {}

  public initialize(): Observable<Coupon[]> {
    return this.checkoutCouponsService
      .updateCheckoutCoupons()
      .pipe(tap((coupons) => this.handleCouponPreselectionFlow(coupons)));
  }

  private handleCouponPreselectionFlow(coupons: Coupon[]): void {
    if (!coupons?.length) return;
    const userLastSelectedCoupon = this.couponsService.getLastSelectedCoupon();
    const userSelectedCoupon = coupons.find((coupon) => coupon.code === userLastSelectedCoupon?.code);
    const checkoutPreselectedCoupon = coupons.find((coupon) => isCouponCheckoutPreselected(coupon));
    if (!checkoutPreselectedCoupon && !userSelectedCoupon) return;
    const cardPayment = this.paymentTypesService.getCardPayment();
    if (!cardPayment) return;
    this.checkoutCouponsService.selectCoupon(userSelectedCoupon ?? checkoutPreselectedCoupon);
    if (this.paymentTypesService.isCurrentPaymentCard()) return;
    this.paymentTypesService.setPaymentType(cardPayment);
    this.showCouponPreselectionDialog();
  }

  private showCouponPreselectionDialog(): void {
    const infoConfig = {
      title: 'you_have_a_discount',
      messages: ['to_get_the_discount_we_set_payment_by_card_as_the_payment_method']
    };
    this.dialogService.openInfoDialog(infoConfig);
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Coupon, GACouponConfig } from '@box-types';
import { SwiperOptions } from 'swiper/types';
import { AnalyticsService, CouponsService, DialogService, ShopsService } from '@box-core/services';
import { Router } from '@angular/router';
import { Mousewheel, Navigation } from 'swiper/modules';
import { HomeSection } from '@box/utils';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  CouponDetailsDialogData,
  CouponDetailsDialogResponse
} from '@box-coupon-widget/components/coupon-details-dialog/coupon-details-dialog.types';
import { CouponDetailsDialogComponent } from '@box-coupon-widget/components';
import { Observable } from 'rxjs';

@Component({
  selector: 'home-coupons-section',
  templateUrl: './home-coupons-section.component.html',
  styleUrls: ['./home-coupons-section.component.scss']
})
export class HomeCouponsSectionComponent implements OnInit, AfterViewInit {
  @ViewChild('nextBtn', { static: true }) private nextBtn: MatButton;
  @ViewChild('prevBtn', { static: true }) private prevBtn: MatButton;
  @ViewChild('navButtonContainer', { static: true }) private navButtonContainer: ElementRef<HTMLDivElement>;

  @Input() public homeSection: HomeSection;
  public coupons: Coupon[];
  public title: string;
  public swiperOptions: SwiperOptions;
  public readonly DEFAULT_INTERSECTION_THRESHOLDS = 0.8;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private analyticsService: AnalyticsService,
    private dialogService: DialogService,
    private shopsService: ShopsService,
    private changeDetectorRef: ChangeDetectorRef,
    private couponsService: CouponsService
  ) {}

  ngOnInit(): void {
    this.setSectionContent();
    this.setSwiperOptions();
  }

  // todo lots of duplicate code related to swiper written in home-shops-section contests-section and promo-banners
  ngAfterViewInit(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const buttonContainer: HTMLDivElement = this.navButtonContainer.nativeElement;
    const nextButtonIsDisabled = nextEl.classList.contains('swiper-button-disabled');
    const prevButtonIsDisabled = prevEl.classList.contains('swiper-button-disabled');
    if (nextButtonIsDisabled && prevButtonIsDisabled) {
      this.renderer.addClass(buttonContainer, 'hidden');
    }
    this.changeDetectorRef.detach();
  }

  private setSectionContent(): void {
    this.title = this.homeSection.title;
    this.coupons = this.homeSection.coupons;
  }

  public onCouponClick(coupon: Coupon): void {
    this.triggerAnalyticsCouponClickEvent(coupon);
    if (coupon.eligibleShops?.length === 1) {
      this.triggerAnalyticsShowViewFromCouponClickEvent(coupon);
      this.couponsService.setLastSelectedCoupon(coupon, 'home');
      return void this.router.navigate([
        '/delivery',
        coupon.eligibleShops[0].locationKey,
        coupon.eligibleShops[0].vanity_url
      ]);
    } else if (coupon.eligibleShops?.length > 1) {
      this.openCouponDetails(coupon).subscribe((response) => {
        if (response?.triggerShop) this.couponsService.setLastSelectedCoupon(coupon, 'home');
      });
    }
  }

  public openCouponDetails(coupon: Coupon): Observable<CouponDetailsDialogResponse> {
    const dialogConfig: MatDialogConfig<CouponDetailsDialogData> = {
      panelClass: 'box-dialog-fit-content',
      data: { coupon, shops: this.shopsService.getShops(), showCta: true, source: 'home' }
    };
    return this.dialogService
      .openDialog<CouponDetailsDialogComponent, CouponDetailsDialogResponse>(CouponDetailsDialogComponent, dialogConfig)
      .afterClosed();
  }

  public onCouponEnteredViewport(coupon: Coupon): void {
    this.triggerAnalyticsCouponImpressionEvent(coupon);
  }

  private setSwiperOptions(): void {
    const nextEl: HTMLElement = (this.nextBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    const prevEl: HTMLElement = (this.prevBtn._elementRef as ElementRef<HTMLButtonElement>).nativeElement;
    this.swiperOptions = {
      modules: [Navigation, Mousewheel],
      navigation: { nextEl, prevEl },
      mousewheel: { forceToAxis: true, releaseOnEdges: false },
      slidesPerView: 'auto',
      grabCursor: true,
      spaceBetween: 16
    };
  }

  private triggerAnalyticsShowViewFromCouponClickEvent(coupon: Coupon): void {
    const gaConfig = { source: 'home', synergy: coupon.synergy } as GACouponConfig;
    this.analyticsService.addGACustomEvent('shop_view_from_coupon', gaConfig);
  }

  private triggerAnalyticsCouponImpressionEvent(coupon: Coupon): void {
    const gaConfig = { source: 'home', synergy: coupon.synergy } as GACouponConfig;
    this.analyticsService.addGACustomEvent('coupon_impression', gaConfig);
  }

  private triggerAnalyticsCouponClickEvent(coupon: Coupon): void {
    const gaConfig = { source: 'home', synergy: coupon.synergy } as GACouponConfig;
    this.analyticsService.addGACustomEvent('coupon_click', gaConfig);
  }
}

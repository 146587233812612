import { Cuisine, Shop, Chain, Coupon, GetTextByKeyType, CurrencyCode } from '@box-types';
import dayjs from 'dayjs';
import { generateImageSrc } from '../media';
import { getOutputBlockHtml } from '../block-editor';
import { isCouponDisabled, isCouponLoyalty } from './coupon.utils';
import { filterItemShops, currencyFormat, formatPoints, CurrencyFormatOptions } from '../core';

const COUPON_CHECKOUT_IMAGE_PATH = '/assets/images/checkout';
const COUPON_DFU_IMAGE_NAME = 'checkout-coupon-dfu';
const COUPON_CHECKOUT_DEFAULT_IMAGE_NAME = 'checkout-coupon';

function getCouponPrimaryText(coupon: Coupon, currencyCode: CurrencyCode): string {
  const { benefitType, amount, percentage, loyaltyPoints, info } = coupon;
  const primaryText = info?.primaryText;
  if (primaryText) return primaryText;
  switch (benefitType) {
    case 'DISCOUNT_FIXED':
      return '-' + currencyFormat(amount, { minimumFractionDigits: 0, currencyCode, symbolSpace: false });
    case 'DISCOUNT_PERCENTAGE':
      return '-' + new Intl.NumberFormat('el-GR', { style: 'percent' }).format(percentage / 100);
    case 'LOYALTY_POINTS':
      return formatPoints(loyaltyPoints);
  }
}

function getCouponPrimaryTextColor(coupon: Coupon): string {
  const { info } = coupon;
  return info?.primaryTextColor ? info.primaryTextColor : '#F87A1D';
}

function getCouponSecondaryText(coupon: Coupon): string {
  const { benefitType, info } = coupon;
  const secondaryText = info?.secondaryText;
  if (secondaryText) return secondaryText;
  /* The case of benefitType === PERSONALISED_CAMPAIGN without secondaryText is not valid
  we should filter those coupons during the api fetch */
  switch (benefitType) {
    case 'DISCOUNT_FIXED':
    case 'DISCOUNT_PERCENTAGE':
      return 'discount_';
    case 'LOYALTY_POINTS':
      return 'points_uppercase';
  }
}

function getCouponSecondaryTextColor(coupon: Coupon): string {
  const { info } = coupon;
  return info?.secondaryTextColor ? info.secondaryTextColor : '#F87A1D';
}

function getCouponLogo(coupon: Coupon): string {
  const { info } = coupon;
  if (!info?.logo) return;
  return generateImageSrc(info.logo);
}

function shouldShowCouponExpirationTime(coupon: Coupon): boolean {
  const date = dayjs(coupon.expirationDate);
  const hours = date.get('hours');
  const minutes = date.get('minutes');
  const seconds = date.get('seconds');
  if (hours === 23 && minutes === 59 && seconds === 59) return false;
  return true;
}

function getCouponExpirationText(coupon: Coupon, translateFn: GetTextByKeyType): string {
  const { available, expirationDate, redemptionDate } = coupon;
  if (!available && redemptionDate) return translateFn('redeemed_');
  if (!available && expirationDate) return translateFn('expired_');
}

function getCouponDetailsDescription(coupon: Coupon): string {
  const blocks = coupon.info?.detailsDescription?.blocks;
  if (!blocks?.length) return;
  return blocks.map((block) => getOutputBlockHtml(block)).join('');
}

function getCouponShortDescription(coupon: Coupon, translateFn: GetTextByKeyType): string {
  const eligibleShopsCount = coupon?.eligibleShops?.length;
  if (eligibleShopsCount === 1) return translateFn('in_shop', { SHOP_NAME: coupon?.eligibleShops[0]?.name });

  const blocks = coupon.info?.shortDescription?.blocks;
  if (!blocks?.length) return;
  const combinedBlocks = blocks.map((block) => getOutputBlockHtml(block)).join('');

  if (eligibleShopsCount > 1 && combinedBlocks.includes('SHOPS_COUNT')) {
    return combinedBlocks.replace('SHOPS_COUNT', String(eligibleShopsCount));
  }
  return combinedBlocks;
}

function getCouponDetailsExpirationDateText(coupon: Coupon, translateFn: GetTextByKeyType): string {
  if (!coupon.expirationDate) return;
  const showTime = shouldShowCouponExpirationTime(coupon);
  const formatText = showTime ? 'DD/MM/YY, HH:mm:ss' : 'DD/MM/YY';
  return translateFn('until_date', {
    _DATE: dayjs(coupon.expirationDate).format(formatText)
  });
}

function getCouponDetailsRedemptionDateText(coupon: Coupon): string {
  if (!coupon.redemptionDate) return;
  if (isCouponDisabled(coupon)) return dayjs(coupon.redemptionDate).format('DD/MM/YY, HH:mm:ss');
}

function getCouponDetailsRemainingValueText(coupon: Coupon, currencyCode: CurrencyCode): string {
  if (!coupon.info?.remainingValue) return;
  const formatOptions: CurrencyFormatOptions = { currencyCode, symbolSpace: false, minimumFractionDigits: 2 };
  return currencyFormat(coupon.info.remainingValue, formatOptions);
}

function getCouponEligibleShops(coupon: Coupon, shops: Shop[], cuisines: Cuisine[], chains: Chain[]): Shop[] {
  const filteringOptions = coupon?.info?.shopsFiltering;
  return filterItemShops(filteringOptions, shops, { cuisines, chains });
}

function getCouponDetailsCtaTitle(coupon: Coupon): string {
  if (isCouponLoyalty(coupon)) return 'activate_';
  return coupon.info?.ctaTitle;
}

function getDefaultCheckoutCouponToggleActiveImage(): string {
  return `${COUPON_CHECKOUT_IMAGE_PATH}/${COUPON_CHECKOUT_DEFAULT_IMAGE_NAME}-active.svg`;
}

function getDFUCheckoutCouponToggleActiveImage() {
  return `${COUPON_CHECKOUT_IMAGE_PATH}/${COUPON_DFU_IMAGE_NAME}-active.png`;
}

function couponSupportsCustomActiveState(coupon: Coupon): boolean {
  const { info } = coupon;
  if (!info) return false;
  return Boolean(info.checkoutBackgroundColor && info.primaryTextColor);
}

function getCouponCustomActiveImage(coupon: Coupon): string {
  const imgUrl = generateImageSrc(coupon.info?.bannerImage?.checkoutChecked);
  if (imgUrl) return imgUrl;
  const isDFU = coupon.type === 'DFY';
  if (isDFU) return getDFUCheckoutCouponToggleActiveImage();
}

function getCouponImagePath(coupon: Coupon): string {
  if (!coupon) return;
  const customImg = getCouponCustomActiveImage(coupon);
  if (customImg) return customImg;
  if (coupon.loyaltyPoints) return getDefaultCheckoutCouponToggleActiveImage();
}

function showCheckoutCouponCustomActiveState(coupon: Coupon): boolean {
  if (!coupon) return false;
  return !getCouponCustomActiveImage(coupon) && couponSupportsCustomActiveState(coupon);
}

function showCheckoutCouponDefaultState(coupon: Coupon): boolean {
  if (!coupon) return true;
  return !getCouponCustomActiveImage(coupon) && !couponSupportsCustomActiveState(coupon);
}

function getCouponPrimaryTextShadowColorLight(coupon: Coupon): string {
  const { info } = coupon;
  return info?.primaryTextShadowColorLight ? info.primaryTextShadowColorLight : '#2A292E';
}

function getCouponPrimaryTextShadowColorDark(coupon: Coupon): string {
  const { info } = coupon;
  return info?.primaryTextShadowColorDark ? info.primaryTextShadowColorDark : '#2A292E';
}

export {
  COUPON_CHECKOUT_IMAGE_PATH,
  COUPON_DFU_IMAGE_NAME,
  COUPON_CHECKOUT_DEFAULT_IMAGE_NAME,
  getCouponPrimaryText,
  getCouponPrimaryTextColor,
  getCouponSecondaryText,
  getCouponSecondaryTextColor,
  getCouponLogo,
  shouldShowCouponExpirationTime,
  getCouponExpirationText,
  getCouponDetailsDescription,
  getCouponDetailsExpirationDateText,
  getCouponDetailsRedemptionDateText,
  getCouponDetailsRemainingValueText,
  getCouponEligibleShops,
  getCouponDetailsCtaTitle,
  couponSupportsCustomActiveState,
  getCouponImagePath,
  getCouponCustomActiveImage,
  getDefaultCheckoutCouponToggleActiveImage,
  getDFUCheckoutCouponToggleActiveImage,
  showCheckoutCouponCustomActiveState,
  showCheckoutCouponDefaultState,
  getCouponPrimaryTextShadowColorLight,
  getCouponPrimaryTextShadowColorDark,
  getCouponShortDescription
};

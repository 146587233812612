<div class="coupon-countdown-container" [ngClass]="{ 'coupon-countdown-container-no-padding': minutesTemplate }">
  <ng-container *ngIf="minutesTemplate; else hoursOrDays" class="coupon-countdown-hours">
    <span class="ods-typography-microcopy-bold coupon-countdown-text">{{ minutes }}</span>
    <span class="ods-typography-microcopy-bold coupon-countdown-text coupon-countdown-separator">:</span>
    <span class="ods-typography-microcopy-bold coupon-countdown-text">{{ seconds }}</span>
  </ng-container>
  <ng-template #hoursOrDays class="coupon-countdown-days">
    <svg-icon [svgPath]="iconPath" [attr.aria-label]="'calendar'" class="coupon-calendar-icon"></svg-icon>
    <span *ngIf="hoursTemplate" class="ods-typography-microcopy-bold coupon-countdown-text">{{
      hours + ('hour_shorthand' | translate)
    }}</span>
    <span *ngIf="daysTemplate" class="ods-typography-microcopy-bold coupon-countdown-text">{{
      days + ('day_shorthand' | translate)
    }}</span>
  </ng-template>
</div>

<section class="cookies-consent-settings-description">
  <p *ngFor="let infoText of cookiesConsentData.settingsInfo">
    {{ infoText.text }}
  </p>
</section>
<cookies-toggles
  [cookiesToggles]="cookiesConsentData.settingsToggle"
  [partnersNumber]="cookiesConsentData.partnersNumber"
  (cookiesConsentClose)="onSubmit()"
  (cookiesConsentBack)="onBack()"
></cookies-toggles>

<div class="cookies-toggles-sliders">
  <div class="cookies-toggles-links-container">
    <a
      [attr.href]="['/account/personal-data/policy']"
      target="_blank"
      rel="noopener noreferrer"
      [attr.aria-label]="'privacy_policy_inform' | translate"
      class="cookies-toggles-link"
      >{{ 'privacy_policy_inform' | translate }}</a
    >
    <a
      [routerLink]="['/account/personal-data/policy']"
      fragment="partner_list_link"
      target="_blank"
      rel="noopener noreferrer"
      [attr.aria-label]="'partner_list' | translate: { _PARTNERS_NUMBER: partnersNumber.toString() }"
      class="cookies-toggles-link"
    >
      {{ 'partner_list' | translate: { _PARTNERS_NUMBER: partnersNumber.toString() } }}
    </a>
  </div>

  <div class="cookies-toggles-slider-content">
    <div class="cookies-toggles-slider-first-row">
      <strong>{{ cookiesToggles[0].title | translate }}</strong>
      <div class="cookies-toggles-slide-container">
        <mat-slide-toggle disabled color="primary" [checked]="mandatoryAccepted"></mat-slide-toggle>
        <span class="cookies-toggles-slider-text">{{ 'always_on' | translate }}</span>
      </div>
    </div>
    <span class="cookies-toggles-slider-first-row-text">{{ cookiesToggles[0].description | translate }}</span>
  </div>

  <div class="cookies-toggles-slider-content">
    <div class="cookies-toggles-slider-first-row">
      <strong>{{ cookiesToggles[1].title }}</strong>
      <div class="cookies-toggles-slide-container">
        <mat-slide-toggle
          color="primary"
          [checked]="performanceAccepted"
          (change)="onChangeConsent('performance', $event)"
        ></mat-slide-toggle>
        <span class="cookies-toggles-slider-text">
          {{ performanceAccepted ? ('on_' | translate) : ('off_' | translate) }}</span
        >
      </div>
    </div>
    <span class="cookies-toggles-slider-first-row-text">{{ cookiesToggles[1].description | translate }}</span>
    <div class="cookies-toggles-more-info">
      <button class="cookies-toggles-more-info-toggle" type="button" (click)="toggleMoreDescription(1)">
        <span>{{ 'read_more' | translate }}</span>
        <mat-icon class="cookies-toggles-more-info-toggle-icon" fontSet="material-icons-round">
          {{ cookiesToggles[1].moreActive ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>
      <div *ngIf="cookiesToggles[1].moreActive" class="cookies-toggles-more-info-toggle-text">
        <span>{{ cookiesToggles[1].moreDescription }}</span>
      </div>
    </div>
  </div>

  <div class="cookies-toggles-slider-content">
    <div class="cookies-toggles-slider-first-row">
      <strong>{{ cookiesToggles[2].title | translate }}</strong>
      <div class="cookies-toggles-slide-container">
        <mat-slide-toggle
          color="primary"
          [checked]="adAccepted"
          (change)="onChangeConsent('ad', $event)"
        ></mat-slide-toggle>
        <span class="cookies-toggles-slider-text"> {{ adAccepted ? ('on_' | translate) : ('off_' | translate) }}</span>
      </div>
    </div>
    <span class="cookies-toggles-slider-first-row-text">{{ cookiesToggles[2].description | translate }}</span>
    <div class="cookies-toggles-more-info">
      <button class="cookies-toggles-more-info-toggle" type="button" (click)="toggleMoreDescription(2)">
        <span>{{ 'read_more' | translate }}</span>
        <mat-icon class="cookies-toggles-more-info-toggle-icon" fontSet="material-icons-round">
          {{ cookiesToggles[2].moreActive ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </button>
      <div *ngIf="cookiesToggles[2].moreActive" class="cookies-toggles-more-info-toggle-text">
        <span>{{ cookiesToggles[2].moreDescription }}</span>
      </div>
    </div>
  </div>

  <div class="cookies-toggles-slider-content-no-border">
    <div class="cookies-toggles-slider-first-row">
      <strong>{{ cookiesToggles[3].title | translate }}</strong>
      <div class="cookies-toggles-slide-container">
        <mat-slide-toggle
          color="primary"
          [checked]="uxAccepted"
          (change)="onChangeConsent('ux', $event)"
        ></mat-slide-toggle>
        <span class="cookies-toggles-slider-text"> {{ uxAccepted ? ('on_' | translate) : ('off_' | translate) }}</span>
      </div>
    </div>
    <span class="cookies-toggles-slider-first-row-text">{{ cookiesToggles[3].description | translate }}</span>
  </div>
</div>
<footer #dialogFooter class="box-dialog-footer cookies-toggles-footer">
  <div class="cookies-toggles-buttons-container">
    <button box-button theme="secondary" borders="none" class="cookies-toggles-back-button" (click)="onClickBack()">
      <mat-icon class="cookies-toggles-back-button-icon">arrow_back</mat-icon>
      {{ 'back_' | translate }}
    </button>
    <div class="cookies-toggles-right-buttons">
      <button box-button theme="primary" (click)="onSave()">
        {{ 'only_selected' | translate }}
      </button>
      <button box-button theme="primary" (click)="onAcceptAll()">
        {{ 'accept_all' | translate }}
      </button>
    </div>
  </div>
</footer>

import {
  HomeTimeRange,
  HomeViewSorting,
  Order,
  PromoCampaign,
  DiscoverFilter,
  Shop,
  Contest,
  Cuisine,
  ChainTile,
  Chain,
  Address,
  Timeslot,
  BusinessVertical,
  Configuration,
  CollapsibleTile,
  PromoBanner,
  Coupon
} from '@box-types';
import dayjs from 'dayjs';
import { HomeSection, CuisinesSchedule } from './home.types';
import { filterHomeSectionShops, sortHomeSectionShops } from './home-section.utils';
import { filterItemShops, isCurrentTimeInTimeRange, isShopFilterableItem } from '../core';
import { CollectionCampaign, CollectionCampaignsSession } from '../promo-campaigns';
import { storageGet, storageSet, storageRemove } from '../storage';
import { shouldShowHomeViewBAFBanner } from '../baf';
import { getClosestShopToAddressPerChain, filterShopsByMainCuisines } from '../shops';
import { cuisineToCollapsibleTile } from '../cuisines';
import { isOrderInProgress } from '../order';
import { sortAvailableCoupons } from '../coupons/coupon-sorting.utils';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export {
  getSortedPriorityCollectionCampaigns,
  canCollectionCampaignBeSeen,
  getMainViewTileCurrentPosition,
  shouldShowShopsHomeSectionCTA,
  decorateAndFilterBannerSections,
  decorateAndFilterContestSections,
  decorateAndFilterShopSections,
  decorateAndFilterCuisineSections,
  getActiveCuisineSchedules,
  decorateAndFilterFiltersSections,
  decorateFilterAndSortMainViewCuisines,
  updateCollectionCampaignSession,
  decorateAndFilterSingleBannersSections,
  removeCollectionCampaignsSession,
  decoratePendingOrdersSection,
  decoratePopularBrandsSection,
  generateChainTiles,
  decorateAndFilterCouponsSections,
  filterHomeSectionCoupons
};

function getSortedPriorityCollectionCampaigns(collectionCampaigns: PromoCampaign[]): PromoCampaign[] {
  if (!collectionCampaigns?.length) return [];
  return collectionCampaigns.sort((a, b) => b.priority - a.priority);
}

function updateCollectionCampaignSession(collectionCampaigns: CollectionCampaign[]): void {
  const seenTimestamps: CollectionCampaignsSession = storageGet('Box.collectionCampaignsSession', localStorage) ?? {};
  const currentTimestamp = dayjs().valueOf();
  collectionCampaigns.forEach((campaign) => (seenTimestamps[campaign.campaignName] = currentTimestamp));
  storageSet('Box.collectionCampaignsSession', { ...seenTimestamps }, localStorage);
}

function removeCollectionCampaignsSession(collectionCampaignNames?: string[]): void {
  if (!collectionCampaignNames?.length) return storageRemove('Box.collectionCampaignsSession', localStorage);
  const seenTimestamps: CollectionCampaignsSession = storageGet('Box.collectionCampaignsSession', localStorage) ?? {};
  collectionCampaignNames.forEach((name) => delete seenTimestamps[name]);
  storageSet('Box.collectionCampaignsSession', { ...seenTimestamps }, localStorage);
}

function canCollectionCampaignBeSeen(collectionCampaign: PromoCampaign): boolean {
  if (!collectionCampaign?.campaignModal) return false;
  const localSession: CollectionCampaignsSession = storageGet('Box.collectionCampaignsSession', window.localStorage);
  if (!localSession) return true;
  const seenTimestamp = localSession[collectionCampaign.name];
  if (!seenTimestamp) return true;
  const frequencyInMinutes = collectionCampaign.campaignModal.frequencyInMinutes;
  if (frequencyInMinutes <= 0) return true;
  const currentDate = dayjs();
  const campaignNextSessionDate = dayjs(seenTimestamp).add(frequencyInMinutes, 'minute');
  return currentDate.isAfter(campaignNextSessionDate);
}

function getCurrentTimeRange(timeRanges: HomeTimeRange[]): HomeTimeRange {
  if (!timeRanges || timeRanges.length === 0) return undefined;
  return timeRanges.find((tr) => isCurrentTimeInTimeRange(tr));
}

function getMainViewTileCurrentPosition(sortings: HomeViewSorting[]): number {
  if (!sortings?.length) return undefined;
  const today: string = dayjs().locale('en').format('dddd');
  const dailySorting = sortings.find((sorting) => sorting.day === today);
  if (!dailySorting) return undefined;
  const currentTimeRange = getCurrentTimeRange(dailySorting.timeRanges);
  if (!currentTimeRange) return undefined;
  return currentTimeRange.position;
}

function getActiveCuisineSchedules(cuisinesSchedules: CuisinesSchedule[]): CuisinesSchedule[] {
  if (!cuisinesSchedules?.length) return [];
  const today: string = dayjs().locale('en').format('dddd');

  return cuisinesSchedules.filter((cuisinesSchedule) => {
    if (!cuisinesSchedule?.days?.length || !cuisinesSchedule.timeRanges?.length) return false;
    const scheduleActiveToday = cuisinesSchedule.days.some((day) => day === today);
    if (!scheduleActiveToday) return false;
    const currentTimeRange = getCurrentTimeRange(cuisinesSchedule.timeRanges);
    if (!currentTimeRange) return false;
    return true;
  });
}

/** shouldShowShopsHomeSectionCTA should only be used for the `homeSection` with the type `shops`.
 * Checks if the `homeSection.maximumShopsNumber` is exceeded by the filtered shops and also if the
 * `homeSection.webAction` exists.
 * @param {HomeSection} homeSection The HomeSection to be checked
 * @param {number} shopsNumber The length of the HomeSection shops
 * @returns {boolean}
 */
function shouldShowShopsHomeSectionCTA(homeSection: HomeSection, shopsNumber: number): boolean {
  const { maximumShopsNumber, webAction } = homeSection;
  if (shopsNumber <= maximumShopsNumber) return false;
  return Boolean(webAction);
}

function decorateAndFilterBannerSections(sections: HomeSection[], promoBanners: PromoBanner[]): HomeSection[] {
  return sections
    .map((section) => ({ ...section, banners: promoBanners }))
    .filter((section) => Boolean(section.banners?.length));
}

function decorateAndFilterSingleBannersSections(
  sections: HomeSection[],
  config: Configuration,
  isGuest: boolean
): HomeSection[] {
  if (isGuest || !config.singleBannerSectionV2 || !shouldShowHomeViewBAFBanner()) return [];

  const requiredAssets = config.singleBannerSectionV2.title && config.singleBannerSectionV2.description;
  if (!requiredAssets) return [];

  const singleBannerConfig = { ...config.singleBannerSectionV2, closeButton: true };

  return sections.map((section) => ({ ...section, singleBannerConfig }));
}

function decorateAndFilterContestSections(sections: HomeSection[], contests: Contest[], shops: Shop[]): HomeSection[] {
  const filteredContests = contests.reduce((acc: Contest[], contest) => {
    if (isShopFilterableItem(contest)) {
      // if there are shop filtering rules,
      // the contest can only be shown if there are matching shops
      const contestShops = filterItemShops(contest, shops);
      if (contestShops?.length) acc.push(contest);
    } else {
      acc.push(contest);
    }
    return acc;
  }, [] as Contest[]);

  return sections.reduce((acc: HomeSection[], section) => {
    section = { ...section, contests: filteredContests };
    if (section.contests?.length) acc.push(section);
    return acc;
  }, [] as HomeSection[]);
}

function decorateAndFilterFiltersSections(
  sections: HomeSection[],
  shops: Shop[],
  discoverFilters: DiscoverFilter[]
): HomeSection[] {
  return sections
    .map((section) => ({ ...section, filteredShops: shops, discoverFilters }))
    .filter((section) => section.discoverFilters?.length);
}

// we want to transcend the shops sorting to the chains sorting, that's why this function looks complicated
function generateChainTiles(shopsWithUniqChain: Shop[], chains: Chain[]): ChainTile[] {
  if (!shopsWithUniqChain?.length || !chains?.length) return [];
  return shopsWithUniqChain.reduce((chainTiles, shop) => {
    if (!shop?.chain) return chainTiles;
    const correspondingChain = chains.find((chain) => chain.chainKey === shop.chain);
    if (correspondingChain) chainTiles.push({ chain: correspondingChain, shop: shop });
    return chainTiles;
  }, [] as ChainTile[]);
}

function decoratePopularBrandsSection(
  sections: HomeSection[],
  chains: Chain[],
  shops: Shop[],
  address: Address
): HomeSection[] {
  if (!shops?.length || !chains?.length || !sections?.length) return [];
  const openPopularShops = shops.filter(
    (shop) => shop.operatingState === 'OPEN' && shop.chain && shop.businessVertical === 'food'
  );
  return sections.reduce((decoratedSections: HomeSection[], section: HomeSection) => {
    const closestShopsWithUniqueChains = getClosestShopToAddressPerChain(address, openPopularShops);
    if (!closestShopsWithUniqueChains?.length) return decoratedSections;
    const sortedClosestShops = sortHomeSectionShops(section, closestShopsWithUniqueChains);
    const chainTiles = generateChainTiles(sortedClosestShops, chains);
    const slicedChainTiles = chainTiles.slice(0, section.maximumShopsNumber);
    const chainsLength = slicedChainTiles.length;
    if (chainsLength === 0) return decoratedSections;
    const minimumChains = section.minimumShopsNumber ?? 0;
    if (chainsLength < minimumChains) return decoratedSections;
    decoratedSections.push({ ...section, chainTiles: slicedChainTiles });
    return decoratedSections;
  }, []);
}

// todo revisit too many Params
function decorateAndFilterShopSections(
  sections: HomeSection[],
  shops: Shop[],
  cuisines: Cuisine[],
  orders: Order[],
  chains: Chain[],
  timeslot: Timeslot
): HomeSection[] {
  const openShops = shops.filter((shop) => shop.operatingState === 'OPEN');
  return sections.reduce((sections: HomeSection[], section: HomeSection) => {
    const { minimumShopsNumber, maximumShopsNumber } = section;
    if (maximumShopsNumber === 0) return sections;
    const filteredShops = filterHomeSectionShops(section, openShops, { cuisines, orders, chains });
    if (filteredShops.length <= minimumShopsNumber) return sections;
    const sortedShops = sortHomeSectionShops(section, filteredShops, timeslot);
    const showActionButton = shouldShowShopsHomeSectionCTA(section, sortedShops?.length);
    const visibleShops = sortedShops.slice(0, maximumShopsNumber);
    const decoratedSection = { ...section, filteredShops: visibleShops, showActionButton };
    sections.push(decoratedSection);
    return sections;
  }, []);
}

function decorateFilterAndSortMainViewCuisines(
  cuisinesSchedule: CuisinesSchedule[],
  shops: Shop[],
  cuisines: Cuisine[]
): {
  cuisine: Cuisine;
  shopsAmount: number;
}[] {
  if (!cuisinesSchedule?.length || !shops?.length || !cuisines?.length) return [];
  return cuisinesSchedule
    .flatMap((schedule) => schedule.cuisines)
    .sort((a, b) => a.position - b.position)
    .map((activeCuisine) => {
      const cuisineKeys = activeCuisine.cuisineType;
      const cuisine = cuisines.find((cuisine) => cuisineKeys.includes(cuisine.key));
      if (!cuisine) return;
      const shopsAmount = filterShopsByMainCuisines(shops, [cuisine])?.length;
      if (!shopsAmount) return;
      return {
        cuisine,
        shopsAmount
      };
    })
    .filter(Boolean);
}

function generateMainViewTiles(section: HomeSection, cuisines: Cuisine[], shops: Shop[]): CollapsibleTile<Cuisine>[] {
  if (!cuisines?.length || !shops?.length) return;
  const activeCuisinesSchedule = getActiveCuisineSchedules(section.cuisinesSchedule);
  const mainViewCuisines = decorateFilterAndSortMainViewCuisines(activeCuisinesSchedule, shops, cuisines);
  return mainViewCuisines.flatMap((mainViewCuisines) =>
    cuisineToCollapsibleTile(mainViewCuisines.cuisine, mainViewCuisines.shopsAmount)
  );
}

function decorateAndFilterCuisineSections(
  sections: HomeSection[],
  cuisines: Cuisine[],
  shops: Shop[],
  vertical: BusinessVertical
): HomeSection[] {
  if (!sections?.length || !vertical) return [];
  const cuisinesWithCorrectVertical = cuisines.filter(
    (cuisine) => cuisine.businessVertical === vertical && cuisine.enabled
  );
  const shopsWithCorrectVertical = shops.filter((shop) => shop.businessVertical === vertical);
  return sections
    .map((section) => ({
      ...section,
      showActionButton: Boolean(section.webAction),
      mainViewTiles: generateMainViewTiles(
        section,
        cuisinesWithCorrectVertical,
        shopsWithCorrectVertical
      ) as CollapsibleTile[]
    }))
    .filter((section) => Boolean(section.mainViewTiles?.length));
}

function decoratePendingOrdersSection(section: HomeSection, orders: Order[]): HomeSection {
  const reviewableOrders = orders.filter((order) => isOrderInProgress(order));
  if (!reviewableOrders.length) return;
  return { ...section, orders: reviewableOrders };
}

function filterHomeSectionCoupons(coupons: Coupon[]): Coupon[] {
  if (!coupons?.length) return [];
  // we only show coupons that are discount and not loyalty redemption
  return coupons.filter((coupon) => {
    if (!coupon) return false;
    if (!coupon.eligibleShops?.length) return false;
    if (!coupon.expirationDate) return false;
    const today = dayjs();
    const expirationDate = dayjs(coupon.expirationDate);
    const sevenDaysFromNow = dayjs().add(7, 'day');
    return expirationDate.isBetween(today, sevenDaysFromNow, 'day', '[]'); // '[]' makes it inclusive
  });
}

function decorateAndFilterCouponsSections(sections: HomeSection[], coupons: Coupon[]): HomeSection[] {
  const sectionCoupons = filterHomeSectionCoupons(coupons);
  if (!sections || !sectionCoupons?.length) return [];
  return sections.map((section) => ({
    ...section,
    coupons: sortAvailableCoupons(sectionCoupons)
  }));
}

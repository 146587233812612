import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Review } from '@box-types';
import { ReviewService } from './review.service';

@Component({
  selector: 'review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [ReviewService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewComponent implements OnChanges {
  @Input() public review: Review;

  public comment: string;
  public date: string;
  public initials: string;
  public isPending: boolean;
  public name: string;
  public rating: number;
  public recommendation: string;

  constructor(private reviewService: ReviewService) {}

  @HostBinding('class') public hostClass = 'review';

  ngOnChanges(changes: SimpleChanges): void {
    this.review = changes.review.currentValue as Review;
    this.rating = this.review.rating;
    this.date = this.reviewService.getReviewFormattedDate(this.review);
    this.name = this.reviewService.getReviewName(this.review);
    this.initials = this.reviewService.getReviewInitials(this.review);
    this.recommendation = this.reviewService.getReviewRecommendation(this.review);
  }
}

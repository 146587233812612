<div class="shop-orders-content">
  <div class="shop-orders-container">
    <shop-order
      *ngFor="let order of latestOrders"
      class="shop-order"
      [order]="order"
      (click)="onOrderClick(order)"
    ></shop-order>
  </div>
  <div *ngIf="remainingOrders.length > 0" class="shop-orders-action">
    <button
      box-button
      borders="none"
      theme="secondary"
      size="small"
      class="shop-orders-btn"
      (click)="openOrdersDialog()"
    >
      + {{ remainingOrders.length }}
    </button>
  </div>
</div>

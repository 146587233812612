<component-loader *ngIf="loading"></component-loader>
<div class="box-dialog-wrapper">
  <header #dialogHeader class="box-dialog-header">
    <box-dialog-header title="tell_us_your_opinion_title" (closeDialog)="closeDialog()"></box-dialog-header>
  </header>
  <main #dialogBody bodyScroll (bodyScroll)="onBodyScroll($event)" class="box-dialog-content">
    <div class="review-dialog-container" [ngSwitch]="state">
      <div class="review-dialog-wrapper">
        <ng-container *ngSwitchCase="'PRE_SUBMIT'">
          <div class="review-dialog-questions-wrapper">
            <div class="review-dialog-question">
              <span class="review-dialog-question-title ods-typography-body-s-bold">{{
                'how_was_your_order' | translate
                }}</span>
              <rating class="review-dialog-question-rating" [size]="32" [rating]="rating"
                (changeRating)="onChangeRating($event)"></rating>
            </div>
            <div *ngIf="showDeliveryRating" class="review-dialog-question">
              <span class="review-dialog-question-title ods-typography-body-s-bold">{{
                deliveryRatingTitle | translate
                }}</span>
              <rating class="review-dialog-question-rating" [size]="32" [rating]="deliveryRating"
                (changeRating)="onChangeDeliveryRating($event)"></rating>
            </div>
          </div>
          <div class="review-dialog-points" *ngIf="claimablePoints" [class.last-item]="!rating">
            <span class="ods-typography-body-s-regular">{{ 'tell_us_what_you_thing_and_earn' | translate }} </span>
            <points-tag class="review-dialog-points-tag" [points]="claimablePoints" [simplifiedText]="true">
            </points-tag>
            <span class="ods-typography-body-s-regular"> {{ 'the_points' | translate }}!</span>
          </div>
          <ng-container *ngIf="rating">
            <div class="review-dialog-recommendations" *ngIf="isOrderReviewPositive && recommendations?.length">
              <div class="review-dialog-recommendations-title ods-typography-body-s-bold">
                {{ recommendationsTitle | translate }}
              </div>
              <mat-checkbox class="review-dialog-recommendation" *ngFor="let recommendation of recommendations"
                color="primary" [(ngModel)]="recommendation.checked">
                <span class="review-dialog-recommendation-content ods-typography-body-s-regular">{{
                  recommendation.productName
                  }}</span>
              </mat-checkbox>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'POST_SUBMIT'">
          <div class="review-dialog-thank-you">
            <rating class="review-dialog-thank-you-rating" [size]="32" [rating]="rating" [disabled]="true"></rating>
            <div class="review-dialog-thank-you-title ods-typography-body-s-bold">
              {{ 'thanks_for_your_tip' | translate }}
            </div>
            <div class="review-dialog-points last-item" *ngIf="claimablePoints">
              <span class="review-dialog-points-text">{{ 'you_just_won' | translate }} </span>
              <points-tag class="review-dialog-points-tag" [points]="claimablePoints" [simplifiedText]="true">
              </points-tag>
              <span class="review-dialog-points-text"> {{ 'the_points' | translate }}!</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </main>

  <footer #dialogFooter class="box-dialog-footer review-dialog-footer" [ngSwitch]="state">
    <div class="review-dialog-footer-container">
      <div *ngSwitchCase="'PRE_SUBMIT'" class="review-dialog-footer-pre-submit-container">
        <button box-button theme="primary" class="review-dialog-footer-button review-dialog-footer-button-full-width" (click)="onSubmit()"
          [disabled]="!canSubmit">
          {{ 'submit_' | translate }}
        </button>
        <span class="ods-typography-microcopy-regular review-dialog-footer-legal-disclaimer">{{
          'the_review_will_be_displayed_publicly' | translate }}</span>
      </div>
      <ng-container *ngSwitchCase="'POST_SUBMIT'">
        <button box-button theme="primary" class="review-dialog-footer-button" (click)="closeDialog()">
          {{ 'back_' | translate }}
        </button>
      </ng-container>
    </div>
  </footer>
</div>
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CookiesConsent } from '@box-types';

@Component({
  selector: 'cookies-consent-settings',
  templateUrl: './cookies-consent-settings.component.html',
  styleUrls: ['./cookies-consent-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesConsentSettingsComponent {
  @Input() public cookiesConsentData: CookiesConsent;

  @Output() private cookiesConsentClose = new EventEmitter();
  @Output() private cookiesConsentBack = new EventEmitter();

  constructor() {}

  public onSubmit(): void {
    this.cookiesConsentClose.emit();
  }

  public onBack(): void {
    this.cookiesConsentBack.emit();
  }
}

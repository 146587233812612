import {
  Component,
  HostBinding,
  OnDestroy,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { CouponTimerService } from '@box-core/services';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'coupon-countdown',
  templateUrl: './coupon-countdown.component.html',
  styleUrls: ['./coupon-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponCountdownComponent implements OnInit, OnDestroy {
  @Input() public couponCode: string;
  @Input() public expirationDate: string;

  public seconds: string;
  public minutes: string;
  public hours: string;
  public days: string;
  public iconPath: string;
  public minutesTemplate: boolean;
  public hoursTemplate: boolean;
  public daysTemplate: boolean;

  private countDownSubscription: Subscription;

  constructor(private couponTimerService: CouponTimerService, private changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class') public hostClass = 'coupon-countdown';
  @HostBinding('class.expires-soon') public expiresSoon: boolean;

  ngOnInit(): void {
    this.iconPath = 'assets/images/coupon/calendar.svg';
    this.setCountDownSubscription();
  }

  ngOnDestroy(): void {
    this.countDownSubscription?.unsubscribe();
  }

  private setCountDownSubscription(): void {
    this.countDownSubscription = this.couponTimerService.getCouponCountDown$(this.couponCode).subscribe((countDown) => {
      if (countDown <= 1800) {
        // 30 mins
        this.expiresSoon = true;
        this.changeDetectorRef.markForCheck();
      }
      this.setCouponCountdownTemplate();
      this.setCouponCountdownValues();
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCouponCountdownTemplate(): void {
    const now = dayjs();
    const expirationDate = dayjs(this.expirationDate);

    const diffInSeconds = expirationDate.diff(now, 'second');
    const diffInHours = expirationDate.diff(now, 'hour');
    const diffInDays = expirationDate.diff(now, 'day');

    if (diffInDays >= 1) {
      this.daysTemplate = true;
      this.hoursTemplate = false;
      this.minutesTemplate = false;
    } else if (diffInHours >= 1) {
      this.hoursTemplate = true;
      this.daysTemplate = false;
      this.minutesTemplate = false;
    } else if (diffInSeconds > 0) {
      this.minutesTemplate = true;
      this.hoursTemplate = false;
      this.daysTemplate = false;
    }
  }

  private setCouponCountdownValues(): void {
    const now = dayjs();
    const expiration = dayjs(this.expirationDate);
    const expirationDiffInSeconds = expiration.diff(now, 'second');
    const expirationDuration = dayjs.duration(expirationDiffInSeconds, 'seconds');
    this.days = String(expirationDuration.days());
    this.hours = String(expirationDuration.hours());
    this.minutes = String(expirationDuration.minutes()).padStart(2, '0');
    this.seconds = String(expirationDuration.seconds()).padStart(2, '0');
  }
}

<div class="rewards-balance-badge-wrapper hover-opacity">
  <div class="rewards-balance-badge-container">
    <div class="rewards-balance-badge-coupons" (click)="onClickCoupon()">
      <div class="rewards-balance-badge-coupons-cut"></div>
      <div class="rewards-balance-badge-coupons-number">
        <span class="ods-typography-button-s rewards-balance-badge-coupons-number-text">{{ couponsNumber }}</span>
      </div>
    </div>
    <div class="rewards-balance-badge-points" (click)="onClickPoints()">
      <div class="rewards-balance-badge-points-content ods-typography-body-s-bold" [ngSwitch]="state">
        <div class="rewards-balance-badge-points-text-container" *ngSwitchCase="'POINTS'">
          <div>{{ pointsText }}</div>
          <div>{{ 'points_lowercase' | translate }}</div>
        </div>
        <div class="rewards-balance-badge-points-text-container" *ngSwitchCase="'CURRENCY'">
          <div>{{ 'you_have' | translate }}</div>
          <div>{{ currencyText }}</div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isTutorialOpen">
    <rewards-preview-overlay (closeClick)="onClickClose()" (ctaClick)="onClickCta()"></rewards-preview-overlay>
  </ng-container>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopsWidgetModule } from '@box-shops-widget/shops-widget.module';
import { SharedModule } from '@box-shared/shared.module';

import * as Components from './components';

@NgModule({
  declarations: [
    Components.CouponComponent,
    Components.CouponDetailsDialogComponent,
    Components.CouponRedemptionDialogComponent,
    Components.CouponCodeDialogComponent,
    Components.CouponCountdownComponent
  ],
  imports: [CommonModule, ShopsWidgetModule, SharedModule],
  exports: [
    Components.CouponComponent,
    Components.CouponDetailsDialogComponent,
    Components.CouponRedemptionDialogComponent,
    Components.CouponCodeDialogComponent,
    Components.CouponCountdownComponent
  ]
})
export class CouponWidgetModule {}

import { UsefulLink } from '../types';

export const usefulLinks: UsefulLink[] = [
  {
    title: 'terms_of_use',
    routerLink: '/terms'
  },
  {
    title: 'data_privacy_policy',
    routerLink: '/privacy'
  },
  {
    title: 'faqs_',
    routerLink: '/faqs'
  }
];

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Coupon } from '@box-types';
import { ThemingService } from '@box-core/services/theming.service';
import { CouponTimerService } from '@box-core/services';
import { Subscription } from 'rxjs';
import { couponExpiresWithinDaysRange, getCouponPrimaryTextShadowColor, getPrimaryTextSize } from '@box/utils';
import { translate } from '@box-core/services/language.service';
import { currencyCode } from '@box-core/services/currency.service';
import {
  getCouponPrimaryText,
  getCouponPrimaryTextColor,
  getCouponSecondaryText,
  getCouponSecondaryTextColor,
  getCouponExpirationText,
  getCouponLogo,
  getCouponShortDescription
} from '@box/utils';

@Component({
  selector: 'coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public coupon: Coupon;
  @Input() public active: boolean;
  @Input() public overrideDisabledState: boolean;
  @Input() public showCode: boolean;
  @Output() private expired = new EventEmitter<void>();

  public code: string;
  public available: boolean;
  public primaryText: string;
  public primaryTextColor: string;
  public primaryTextSize: string;
  public secondaryText: string;
  public secondaryTextColor: string;
  public primaryTextShadowColor: string;
  public shortDescription: string;
  public counterfoilText: string;
  public disabledText: string;
  public logo: string;
  public showDisabledBackground: boolean;
  public expirationDate: string;
  public closeToExpiration: boolean;
  public expirationText: string;
  public showCountDown: boolean;

  private couponTimerSubscription: Subscription;
  private expirationSubscription: Subscription;
  private themeSubscription: Subscription;

  constructor(
    private themingService: ThemingService,
    private couponTimerService: CouponTimerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostBinding('class') public hostClass = 'coupon';
  @HostBinding('class.coupon-unavailable') public couponUnAvailable: boolean;
  @HostBinding('class.coupon-disabled') public couponDisabled: boolean;
  @HostBinding('class.coupon-used') public couponUsed: boolean;
  @HostBinding('class.coupon-disabled-background') public couponDisabledBackground: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coupon) {
      this.coupon = changes.coupon.currentValue as Coupon;
      this.code = this.coupon.code;
      this.expirationDate = this.coupon.expirationDate;
      this.primaryText = getCouponPrimaryText(this.coupon, currencyCode);
      this.primaryTextColor = getCouponPrimaryTextColor(this.coupon);
      this.primaryTextSize = getPrimaryTextSize(this.primaryText);
      this.secondaryText = getCouponSecondaryText(this.coupon);
      this.secondaryTextColor = getCouponSecondaryTextColor(this.coupon);
      this.shortDescription = getCouponShortDescription(this.coupon, translate);
      this.expirationText = getCouponExpirationText(this.coupon, translate);
      this.disabledText = this.coupon.disabledText;
      this.couponDisabled = Boolean(this.disabledText);
      this.available = this.coupon.available;
      this.couponUnAvailable = !this.coupon.available;
      this.logo = getCouponLogo(this.coupon);
      this.primaryTextShadowColor = getCouponPrimaryTextShadowColor(this.coupon, this.themingService.getTheme());
      this.closeToExpiration = couponExpiresWithinDaysRange(this.coupon, 7);
    }

    if (changes.overrideDisabledState) {
      this.overrideDisabledState = changes.overrideDisabledState.currentValue as boolean;
    }

    if (changes.active) this.active = changes.active.currentValue as boolean;

    this.couponUsed = this.coupon.available === false && Boolean(this.coupon.redemptionDate);
    this.counterfoilText =
      this.overrideDisabledState && Boolean(this.disabledText) ? this.disabledText : this.expirationText;
    this.showDisabledBackground = Boolean(this.disabledText) && !this.overrideDisabledState;
    this.couponDisabledBackground = this.showDisabledBackground;
  }

  ngOnInit(): void {
    this.setCouponTimerSubscription();
    this.setExpirationSubscription();
    this.setThemeSubscription();
  }

  ngOnDestroy(): void {
    this.couponTimerSubscription?.unsubscribe();
    this.expirationSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  private setThemeSubscription(): void {
    this.themeSubscription = this.themingService.selectedTheme$.subscribe((theme) => {
      this.secondaryTextColor = getCouponSecondaryTextColor(this.coupon);
      this.primaryTextShadowColor = getCouponPrimaryTextShadowColor(this.coupon, theme);
      this.changeDetectorRef.detectChanges();
    });
  }

  private setCouponTimerSubscription(): void {
    this.couponTimerSubscription = this.couponTimerService.getCouponTimerState$(this.code).subscribe((state) => {
      this.showCountDown = state === 'COUPON_ACTIVE' && this.closeToExpiration;
      this.changeDetectorRef.detectChanges();
    });
  }

  private setExpirationSubscription(): void {
    this.expirationSubscription = this.couponTimerService.whenCouponIsExpired$(this.coupon.code).subscribe(() => {
      this.expired.emit();
      this.changeDetectorRef.detectChanges();
    });
  }
}

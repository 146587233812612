<div class="swiper-section box-swiper">
  <div class="swiper-section-header">
    <div class="swiper-section-header-content">
      <h3 class="swiper-section-title">{{ title }}</h3>
    </div>
    <div #navButtonContainer class="swiper-section-navigation">
      <button #prevBtn mat-icon-button class="box-swiper-button-prev">
        <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
      </button>
      <button #nextBtn mat-icon-button class="box-swiper-button-next">
        <mat-icon fontSet="material-icons-round">chevron_right</mat-icon>
      </button>
    </div>
  </div>

  <div [swiper]="swiperOptions" *ngIf="coupons?.length > 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide home-coupons-section-swiper-wrapper" *ngFor="let coupon of coupons">
        <coupon
        [coupon]="coupon"
        visibilityObserver
        [triggerOnce]="true"
        [intersectionThresholds]="DEFAULT_INTERSECTION_THRESHOLDS"
        (enteredViewport)="onCouponEnteredViewport(coupon)"
        (click)="onCouponClick(coupon)"
        class="home-coupons-section-coupon"
      ></coupon>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { PrivacyConsentService } from './privacy-consent.service';
import { environment } from '@box-env/environment';
import { BehaviorSubject } from 'rxjs';
import { PurchaseEvent } from '@box-types';
import { storageGet, storageSet } from '@box/utils';

const PURCHASE_EVENT_STORAGE_KEY = 'Box:purchaseEvent';

declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private readonly purchaseEventsSource = new BehaviorSubject<Record<string, PurchaseEvent>>({});
  public readonly purchaseEvent$ = this.purchaseEventsSource.asObservable();
  private readonly MEASUREMENT_ID = environment.google.MEASUREMENT_ID;

  private readonly EMPTY_PURCHASE_EVENT: PurchaseEvent = {
    creativeName: 'not set',
    index: 0,
    itemListName: 'not set',
    creativeSlot: 'not set',
    promotionName: 'not set'
  };

  constructor(
    private platform: Platform,
    private privacyConsentService: PrivacyConsentService
  ) {
    this.initializePurchaseEvent();
  }

  public addGAUserIdEvent(id: string): void {
    if (!this.permissionGranted('performance')) return;
    gtag('config', this.MEASUREMENT_ID, {
      user_id: id
    });
  }

  public addGACustomEvent<T>(eventName: string, config: T): void {
    if (!this.permissionGranted('performance')) return;
    gtag('event', eventName, config);
  }

  public addGAEcommerceEvent<T>(eventName: string, config: T): void {
    if (!this.permissionGranted('performance')) return;
    gtag('event', eventName, config);
  }

  public addMPCustomEvent<T>(eventName: string, config: T): void {
    if (!this.permissionGranted('performance')) return;
    window.fbq('trackCustom', eventName, config);
  }

  public addMPEvent<T>(eventName: string, config?: T): void {
    if (!this.permissionGranted('performance')) return;
    window.fbq('track', eventName, config);
  }

  private permissionGranted(type: string): boolean {
    if (type === 'performance') return this.performancePermissionGranted();
    if (type === 'advertisement') return this.advertisementPermissionGranted();
  }

  private performancePermissionGranted(): boolean {
    const performanceConsent: boolean = this.privacyConsentService.getPrivacyConsent()?.performance;
    return this.platform.isBrowser && performanceConsent;
  }

  private advertisementPermissionGranted(): boolean {
    const adConsent: boolean = this.privacyConsentService.getPrivacyConsent()?.ad;
    return this.platform.isBrowser && adConsent;
  }

  private initializePurchaseEvent(): void {
    const purchaseEvents = storageGet(PURCHASE_EVENT_STORAGE_KEY, window.sessionStorage);
    if (!purchaseEvents) return;
    const transformedPurchaseEvents = <Record<string, PurchaseEvent>>purchaseEvents;
    this.purchaseEventsSource.next(transformedPurchaseEvents);
  }

  private setPurchaseEvent(shopId: string, purchaseEvent: PurchaseEvent): void {
    const currentpurchaseEvents = { ...this.purchaseEventsSource.getValue() };
    currentpurchaseEvents[shopId] = purchaseEvent;
    this.purchaseEventsSource.next(currentpurchaseEvents);
    storageSet(PURCHASE_EVENT_STORAGE_KEY, this.purchaseEventsSource.getValue(), window.sessionStorage);
  }

  public getPurchaseEvent(shopId: string): PurchaseEvent {
    const purchaseEvents = this.purchaseEventsSource.getValue();
    if (!purchaseEvents) return this.EMPTY_PURCHASE_EVENT;
    return purchaseEvents[shopId] ?? this.EMPTY_PURCHASE_EVENT;
  }

  public updatePurchaseEvent(shopId: string, purchaseEvent: PurchaseEvent): void {
    if (!this.performancePermissionGranted) return;
    if (!purchaseEvent || !shopId) return;
    const currentpurchaseEvent = this.getPurchaseEvent(shopId);
    const newpurchaseEvent = { ...currentpurchaseEvent, ...purchaseEvent };
    this.setPurchaseEvent(shopId, newpurchaseEvent);
  }
}
